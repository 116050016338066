let debugEnabled = document.getElementById("fuzey-decorator")?.getAttribute("debug-enabled")

if (document.readyState === 'complete') {
    decorateForm();
} else if (document.addEventListener) { 
    document.addEventListener('DOMContentLoaded', decorateForm);
} else if (window.addEventListener) {
    window.addEventListener('load', decorateForm)
}

function decorateForm() {
    log("Form decoration started")
    const merchantId = document.getElementById("fuzey-decorator")?.getAttribute("merchant-id");
    if (!merchantId) {
        console.log("Could not decorate form. Merchant is missing.")
        return;
    }

    const formIdStr = document.getElementById("fuzey-decorator")?.getAttribute("form-id");
    const formClassStr = document.getElementById("fuzey-decorator")?.getAttribute("form-class");
    if (formIdStr) {
        formIdStr.split(";").forEach(formId => {
            decorateById(formId, merchantId);
        })
    } else if (formClassStr) {
        formClassStr.split(";").forEach(formClass => {
            decorateByClass(formClass, merchantId);
        })
    } else {
        decorateByTag(merchantId);
    }
}

function decorateById(formId: string, merchantId: string) {
    const form = document.getElementById(formId) as HTMLFormElement
    if (form) {
        form.addEventListener("submit", submitToFuzey(merchantId))
        log("Form decorated by id.")
    } else {
        console.log(`Could not decorate form ${formId}`)
    }
}

function decorateByClass(formClass: string, merchantId: string) {
    const forms = document.querySelectorAll(`form.${formClass}`) as NodeListOf<HTMLFormElement>

    if (forms) {
        forms.forEach((form) => {
            form.onsubmit=submitToFuzey(merchantId)
            log("Form decorated by class.")    
        })
    } else {
        console.log(`Could not decorate form ${formClass}`)
    }
}

function decorateByTag(merchantId: string) {

    const form = findForm(document.getElementsByTagName("form"))

    if (form) {
        form.onsubmit=submitToFuzey(merchantId)
        log("Form decorated by tag name.")
    } else {
        console.log(`Could not decorate form by tag name`)
    }
}

function findForm(forms: HTMLCollectionOf<Element>): HTMLFormElement {
    let form = {} as HTMLFormElement;
    let elementsNo: number = 0;

    for (let i = 0; i < forms.length; i++) {
        let f = forms[i]
        if (f instanceof HTMLFormElement) {
           const formElements = new Set(new FormData(f).keys())
            if (formElements.size >= elementsNo) {
                form = f
                elementsNo = formElements.size
            }
        }
    }

    return form;
}

function submitToFuzey(merchantId: string) {
    return (e: any) => {
        // e.preventDefault();
        try {
            const formData = new FormData(e.target);
            var payload = {};
            formData.forEach((value, key) => {
                if (value && key) {
                    if (payload[key] && Array.isArray(payload[key])) {
                        payload[key] = [value, ...payload[key]]
                    } else if (payload[key]) {
                        payload[key] = [value, payload[key]]
                    } else {
                        payload[key] = value
                    }
                }
            });
            var json = JSON.stringify(payload);
            handleSubmission(merchantId, json);
        } catch (err) {
            console.log("Error pre form submission")
        }
    }
}

function handleSubmission(merchantId: string, payload: string) {
    try {
        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", `${process.env.FUZEY_API_URL}/public/contact-form/decorator/${merchantId}`, false);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(payload);
        // await fetch(
        //     `${process.env.FUZEY_API_URL}/public/contact-form/decorator/${merchantId}`, 
        //     {method: 'POST',  mode: 'cors', headers: {'Content-Type': 'application/json'}, body: payload}
        // )
    } catch (err) {
        console.log("Error while submiting form")
    }
}

function log(message: string) {
    if (debugEnabled) {
        console.log(message);
    }
}
